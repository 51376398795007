export type OGPType = 'website' | 'article';

export interface Breadcrumb {
  label: string;
  routerLink: string;
}

export interface URIMetadata {
  /**
   * ページのタイトルを設定します
   *
   * 'Graat（グラーツ）-グロース・アーキテクチャ＆チームス株式会社'以外の場合は
   *
   * [タイトル] | Graat（グラーツ）-グロース・アーキテクチャ＆チームス株式会社
   *
   * のようにprefixが設定されます。
   */
  title: string;
  description: string;
  image?: string;
  /**
   * @see https://ogp.me/#types
   */
  ogtype?: OGPType;
}

export interface RouteData {
  metadata: URIMetadata;
  breadcrumb: Breadcrumb[];
}

export const creeateRouteData = (data: RouteData): RouteData => data;

export interface LazyLoadRouteData {
  breadcrumb: Breadcrumb[];
}

export const creeateLazyLoadRouteData = (
  data: LazyLoadRouteData
): LazyLoadRouteData => data;
