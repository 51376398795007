<div class="logo">
  <a routerLink="/"
    ><img
      loading="lazy"
      src="https://cdn.graat.co.jp/assets/graat_default.png"
      alt="graat"
  /></a>
</div>
<nav class="navigation">
  <a routerLink="advantage" color="graat" routerLinkActive="active"
    >Graatの強み</a
  >
  <a
    routerLink="service"
    color="graat"
    [class.active]="linkActive('/service', ['/service/support-programs'])"
    >サービス</a
  >
  <a
    routerLink="service/support-programs"
    color="graat"
    [class.active]="linkActive('/service/support-programs', [])"
    >支援プログラム</a
  >
  <a routerLink="case-study" color="graat" routerLinkActive="active"
    >事例紹介</a
  >
  <a routerLink="company" color="graat" routerLinkActive="active">企業情報</a>
  <a routerLink="blogs" color="graat" routerLinkActive="active"
    >ブログ・お知らせ</a
  >
</nav>

<a routerLink="recruit" class="recruit" color="main" color-fill>
  <div class="recruit-icon"></div>
  <div class="recruit-text">採用情報</div>
</a>
<a routerLink="contact" class="inquiry" color="sub" color-fill>
  <div class="inquiry-icon"></div>
  <div class="inquiry-text">お問い合わせ</div>
</a>
<nav class="breadcrumb">
  <ng-template
    ngFor
    let-isFirst="first"
    let-isLast="last"
    let-breadcrumb
    [ngForOf]="breadcrumbs$ | async"
  >
    <ng-container *ngIf="isFirst">
      <a routerLink="/">ホーム</a>
      <span>/</span>
    </ng-container>
    <ng-container *ngIf="isLast; else notLastTemplate">
      <strong>{{ breadcrumb.label }}</strong>
    </ng-container>
    <ng-template #notLastTemplate>
      <a [routerLink]="breadcrumb.routerLink">{{ breadcrumb.label }}</a>
      <span>/</span>
    </ng-template>
  </ng-template>
</nav>
<div class="hamburger">
  <button graat-hp-button color="default" (click)="onMenuOpen()" type="button">
    <img src="https://cdn.graat.co.jp/assets/bars.svg" alt="menu open" />
  </button>
</div>

<div *cdk-portal>
  <nav class="hamburger-wrapper">
    <header>
      <a routerLink="/" (click)="hamburgerPortal.detach()"
        ><img
          loading="lazy"
          src="https://cdn.graat.co.jp/assets/graat_default.png"
          alt="graat"
      /></a>

      <button graat-hp-button color="default" (click)="onMenuClose()">
        <img src="https://cdn.graat.co.jp/assets/times.png" alt="close" />
      </button>
    </header>
    <a
      routerLink="/advantage"
      color="graat"
      routerLinkActive="active"
      (click)="onMenuClose()"
      >Graatの強み</a
    >
    <a
      routerLink="/service"
      color="graat"
      [class.active]="linkActive('/service', ['/service/support-programs'])"
      (click)="onMenuClose()"
      >サービス</a
    >
    <a
      routerLink="service/support-programs"
      color="graat"
      [class.active]="linkActive('/service/support-programs', [])"
      (click)="onMenuClose()"
      >支援プログラム一覧</a
    >
    <a
      routerLink="/case-study"
      color="graat"
      routerLinkActive="active"
      (click)="onMenuClose()"
      >事例紹介</a
    >
    <a
      routerLink="/company"
      color="graat"
      routerLinkActive="active"
      (click)="onMenuClose()"
      >企業情報</a
    >
    <a
      routerLink="/blogs"
      color="graat"
      routerLinkActive="active"
      (click)="onMenuClose()"
      >ブログ・お知らせ</a
    >
    <a
      routerLink="/recruit"
      class="hamburger-wrapper_recruit"
      graat-hp-button
      color="main"
      (click)="onMenuClose()"
    >
      <div class="recruit-icon"></div>
      <div>採用情報</div>
    </a>
    <a
      routerLink="/contact"
      class="hamburger-wrapper_inquiry"
      graat-hp-button
      color="sub"
      (click)="onMenuClose()"
    >
      <div class="inquiry-icon"></div>
      <div>お問い合わせ</div>
    </a>
  </nav>
</div>
