<section class="header">
  <!-- <picture>
    <source srcset="https://cdn.graat.co.jp/assets/pictures/office_420.webp"
      type="image/webp"/>
    <source srcset="https://cdn.graat.co.jp/assets/pictures/office_1280.webp"
      media="(min-width:420px)" type="image/webp" />
    <source srcset="https://cdn.graat.co.jp/assets/pictures/office_1280.png"
      media="(min-width:420px)"/>
    <source srcset="https://cdn.graat.co.jp/assets/pictures/office.webp"
      media="(min-width:1280px)" type="image/webp" />
    <source srcset="https://cdn.graat.co.jp/assets/pictures/office.jpg"
      media="(min-width:1280px)"/>
    <img src="https://cdn.graat.co.jp/assets/pictures/office_420.png"
      alt="Graat 野村ビル オフィス" loading="lazy" decoding="async">
  </picture> -->
  <header class="main-section__width">
    <a
      graat-hp-button
      class="button-suffix-arrow-down"
      color="default"
      [graatHpScrollIntoView]="messageSection"
      >TOP MESSAGE</a
    >
    <a
      graat-hp-button
      class="button-suffix-arrow-down"
      color="default"
      [graatHpScrollIntoView]="missionSection"
      >ビジョンとミッション</a
    >
    <a
      graat-hp-button
      class="button-suffix-arrow-down"
      color="default"
      [graatHpScrollIntoView]="about"
      >会社概要</a
    >
  </header>
  <div class="logo main-section__width">
    <img
      loading="lazy"
      src="https://cdn.graat.co.jp/assets/graat_default_white.png"
      alt="graat inc."
    />
  </div>
</section>
<section class="message main-section__width" #messageSection>
  <h3>TOP MESSAGE</h3>
  <img
    loading="lazy"
    src="https://cdn.graat.co.jp/assets/yusuke.suzuki.png"
    alt="鈴木雄介"
  />
  <article>
    <p>
      既にITは社会基盤としてなくてはならない存在ですが、その活用は一部にとどまっています。
    </p>
    <p>
      例えばヘルスケア。医療機器は高度化し、遠隔治療も可能になりました。しかし、いくら治療行為をIT化しても病気になる人を減らすことはできません。
    </p>
    <p>
      社会全体において効率的なのは予防です。個人が自身の状態を定量的に理解し、健康リスクを把握することができれば、健康を維持しやすくなります。こうしたことは技術的に可能になってきました。ウェアラブルデバイスがバイタルデータをクラウドにあげ、診療結果や検査結果などを蓄積する。このデータを分析すれば健康リスク評価も可能になります。
    </p>
    <p>
      今の業務ありきでIT化するのではなく、ITを前提に新しい業務を創り出す。これがDX（デジタル・トランスフォーメーション）です。
    </p>
    <p>
      Graat（グラーツ）は、今まさに社会基盤を担っている企業のテクノロジーとチームの力を引き出すことでDXが実現され、これからの社会が変わっていくと信じています。
    </p>
  </article>
  <span class="signature">
    <div class="caption">
      <span class="title">代表取締役社長</span>
      <span class="name">鈴木 雄介</span>
    </div>
    <img
      loading="lazy"
      src="https://cdn.graat.co.jp/assets/signature.png"
      alt="鈴木雄介(サイン)"
    />
  </span>
</section>
<section class="mission main-section__width" #missionSection>
  <h3>ビジョンとミッション</h3>
  <article>
    <div graat-hp-tag color="main" tag-layout-only>Vision</div>
    <h5>しなやかな社会の実現</h5>
    <P>
      「しなやかな社会」とは、社会全体が多様性を受け入れながらも、効率的である状態です。「しなやかさ」とは、芯が明確でありながらも、それが固定されているわけではなく、様々な環境の要素に応じて変化することを意味します。ある事象に対して、社会全体が目指すべき方向性は集約されているべきですが、社会に住む多様な個人の状況や意思によって細かい部分はチューニングされる必要があります。
      このためには社会基盤においてITが有効に活用され、多くの人々がデジタルデバイスを通じてさまざまなサービスを受けることが必要です。多様な個人に対する細かなチューニングは、あらかじめ設定されたルールに従ってITが自動的に対応してくれます。
      もちろん、完璧な技術は存在しないので、完璧な「しなやかな社会」が実現されることはありません。しかし、こうした「しなやかな社会」というビジョンを目指して、あらゆる企業が活動し続ける必要があります。
    </P>
  </article>
  <article>
    <div graat-hp-tag color="main" tag-layout-only>Mission</div>
    <h5>
      社会を支え続ける企業が持つ「IT」と「チーム」の力を引き出し、「デジタルトランスフォーメーション」を推進することで、しなやかな社会を実現する
    </h5>
    <P>
      Graatは、しなやかな社会の実現を目指し、社会基盤を担う企業に対してデジタルトランスフォーメーション（DX）を推進するためのサービスを提供します。<br />
      我々の活動領域は、「IT」と「チーム」です。社会基盤を担う企業は、たくさんのITとチームを抱え、日々活動しています。そのITとチームが効率的に、より良い方向に向かうことでDXを推進することが可能になります。
    </P>
  </article>
</section>
<section class="about main-section__width" #about>
  <h3>会社概要</h3>

  <dl itemscope itemtype="https://schema.org/Corporation">
    <dt>名称</dt>
    <dd itemprop="brand">Graat（グラーツ）</dd>
    <dt>会社名</dt>
    <dd itemprop="legalName">
      <ul>
        <li aria-label="日本語会社名">
          グロース・アーキテクチャ＆チームス株式会社
        </li>
        <li aria-label="english corporate name">
          Growth Architectures & Teams, Inc.
        </li>
      </ul>
    </dd>
    <dt>設立日</dt>
    <dd itemprop="dissolutionDate">2018年11月1日</dd>
    <dt>資本金</dt>
    <dd>1,000万円（2020年9月）</dd>
    <dt>代表者</dt>
    <dd itemprop="founder" class="distinctive">
      <dl itemscope itemtype="https://schema.org/Person">
        <dt itemprop="jobTitle">代表取締役社長&nbsp;</dt>
        <dd itemprop="name">鈴木 雄介</dd>
      </dl>
    </dd>
    <dt>経営メンバー</dt>
    <dd itemprop="founder" class="officer">
      <dl itemscope itemtype="https://schema.org/Person">
        <dt itemprop="jobTitle">取締役</dt>
        <dd itemprop="name">&nbsp;渡邉&nbsp;伸一</dd>
        <dt itemprop="jobTitle">取締役</dt>
        <dd itemprop="name">&nbsp;飯沼&nbsp;克哲</dd>
        <dt itemprop="jobTitle">取締役</dt>
        <dd itemprop="name">&nbsp;浅木&nbsp;麗子</dd>
        <br />
        <dt itemprop="jobTitle">監査役</dt>
        <dd itemprop="name">&nbsp;香川&nbsp;朋啓</dd>
        <dt itemprop="jobTitle">監査役</dt>
        <dd itemprop="name">&nbsp;内田&nbsp;裕二</dd>
        <dt itemprop="jobTitle">社外アドバイザー&nbsp;</dt>
        <dd itemprop="name">&nbsp;高江洲&nbsp;睦</dd>
        <dt itemprop="jobTitle">社外アドバイザー</dt>
        <dd itemprop="name">&nbsp;和智&nbsp;右桂</dd>
      </dl>
    </dd>
    <dt>事業内容</dt>
    <dd itemprop="owns">エンタープライズDX推進支援事業</dd>
    <dt>所在地</dt>
    <dd itemprop="address">
      <div>〒163-0548</div>
      <div>
        東京都新宿区西新宿1-26-2 新宿野村ビル48階
        <a href="https://goo.gl/maps/yxM7SvTMeKvtvoZWA">GoogleMap</a>
      </div>
    </dd>
    <dt>グループ企業</dt>
    <dd>
      <ul>
        <li>
          <a href="https://www.gxp-group.co.jp/" target="_blank" rel="noopener"
            >グロースエクスパートナーズ株式会社</a
          >
        </li>
        <li class="small">Graatを含むグループ企業の持ち株会社</li>
        <li>
          <a href="https://www.gxp.co.jp/" target="_blank" rel="noopener"
            >株式会社GxP</a
          >
        </li>
        <li class="small">チーム開発を得意とするエンジニアリング企業</li>
      </ul>
    </dd>
    <dt>公開情報</dt>
    <dd itemscope>
      <ul>
        <li>
          <a routerLink="/privacy" itemprop="url"> プライバシーポリシー</a>
        </li>
        <li>
          <a
            href="https://www.gxp-group.co.jp/isms.html"
            target="_blank"
            rel="noopener"
            >ISMS基本方針</a
          >
        </li>
        <li>
          <a
            href="https://www.gxp-group.co.jp/koukoku/koukoku_graat.html"
            target="_blank"
            rel="noopener"
            >電子公告</a
          >
        </li>
      </ul>
    </dd>
  </dl>
</section>
