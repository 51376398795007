import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'graat-hp-cs-ui-card',
  templateUrl: './cs-ui-card.component.html',
  styleUrls: ['./cs-ui-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsUiCardComponent {
  private _src =
    'https://cdn.graat.co.jp/assets/graat_vertical_padding630x1200.png';
  @Input()
  public set src(value: string) {
    this._src = value || this._src;
  }
  public get src() {
    return this._src;
  }
  @Input()
  public alt = '';
  @Input()
  public headline = '';
  @Input()
  public body = '';
  @Input()
  public routerLink = '';
}
