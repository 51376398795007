import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivationEnd, Event, Router } from '@angular/router';

import { filter, map } from 'rxjs/operators';
import { OGPType, RouteData, URIMetadata } from '../../router.metadata';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(
    private route: Router,
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
    private title: Title
  ) {
    this.route.events
      .pipe<ActivationEnd, URIMetadata, URIMetadata>(
        filter(
          (navigate: Event): navigate is ActivationEnd =>
            navigate instanceof ActivationEnd
        ),
        map(
          (navigate: ActivationEnd) =>
            (navigate.snapshot.data as RouteData)?.metadata
        ),
        filter<URIMetadata>(Boolean)
      )
      .subscribe((metadata) => {
        this.canonicalLink();
        this.setTitle(metadata.title);
        this.setDisctiption(metadata.description);
        this.updateMetaShreImage(
          metadata.image ||
            'https://cdn.graat.co.jp/assets/graat_vertical_padding630x1200.png'
        );
        this.setOgType(metadata.ogtype);
        this.setOgLocale();
      });
  }

  private canonicalLink() {
    const link =
      <HTMLLinkElement>(
        this.document.head.querySelector(`link[rel="canonical"]`)
      ) || this.document.head.appendChild(this.document.createElement(`link`));
    link.rel = 'canonical';
    link.href = `https://www.graat.co.jp${location.pathname}${
      location.pathname.endsWith('/') ? '' : '/'
    }`;
    this.meta.updateTag({ property: 'og:url', content: link.href });
  }

  private setTitle(text: string): void {
    const suffix =
      'Graat（グラーツ）-グロース・アーキテクチャ＆チームス株式会社';
    const setSuffix = (_text: string): string =>
      _text.endsWith(suffix) ? _text : `${_text} | ${suffix}`;
    const content = setSuffix(text);

    this.title.setTitle(content);
    this.meta.updateTag({ property: 'og:title', content });
    this.meta.updateTag({ property: 'twitter:title', content });
  }

  private setDisctiption(description: string) {
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({
      property: 'twitter:description',
      content: description,
    });
  }

  public updateMetaShreImage(content: string): void {
    this.meta.updateTag({
      property: 'og:image',
      content,
    });
    this.meta.updateTag({
      property: 'twitter:image',
      content,
    });
  }

  private setOgType(content: OGPType = 'website') {
    this.meta.updateTag({ property: 'og:type', content });
  }

  private setOgLocale(content = 'ja_JP') {
    this.meta.updateTag({ property: 'og:locale', content });
  }
}
