import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ServiceMenuComponent } from './components/service-menu/service-menu.component';
import { ServiceInsightComponent } from './components/service-insight/service-insight.component';
import { ServiceStepperComponent } from './components/service-stepper/service-stepper.component';
import { ServiceCasesComponent } from './components/service-cases/service-cases.component';
import { TextTemplateComponent } from './components/text-template/text-template.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [
    ServiceMenuComponent,
    ServiceInsightComponent,
    ServiceStepperComponent,
    ServiceCasesComponent,
    TextTemplateComponent,
  ],
  exports: [
    ServiceMenuComponent,
    ServiceInsightComponent,
    ServiceStepperComponent,
    ServiceCasesComponent,
    TextTemplateComponent,
  ],
})
export class ServiceUiModule {}
