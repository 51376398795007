<div class="scrolling">
  <ng-template *ngTemplateOutlet="logos"></ng-template>
  <ng-template
    *ngTemplateOutlet="logos; context: { $implicit: { mobile: true } }"
  ></ng-template>
</div>

<ng-template #logos let-mobile>
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/mihds.png"
    alt="三越伊勢丹ホールディングス"
  />
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/imdl.png"
    alt="株式会社IM Digital Lab"
  />
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/kddi-slogan.png"
    alt="KDDI株式会社"
  />
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/taisei.png"
    alt="大成建設株式会社"
  />
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/njc.png"
    alt="日本事務器株式会社"
  />
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/athome.png"
    alt="アットホーム株式会社"
  />
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/advancedmedia.png"
    alt="株式会社アドバンスト・メディア"
  />
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/lifenet.png"
    alt="ライフネット生命保険株式会社"
  />
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/beng.png"
    alt="ビジネスエンジニアリング株式会社"
  />
  <img
    loading="lazy"
    [class.mobile]="mobile"
    src="https://cdn.graat.co.jp/assets/logos/kwc.png"
    alt="株式会社KDDIウェブコミュニケーションズ"
  />
</ng-template>
