import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Breadcrumb, LazyLoadRouteData } from '@graat-hp/shared/util';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'graat-hp-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  @ViewChild(CdkPortal) hamburgerPortal: CdkPortal;

  private _overlayRef: OverlayRef;

  public readonly breadcrumbs$ = this.router.events.pipe(
    filter((event) => event instanceof ActivationEnd),
    map((event: ActivationEnd) => {
      let root = event.snapshot.root;
      let breadcrumb: Breadcrumb[] = [];
      while (root.firstChild) {
        breadcrumb = [
          ...breadcrumb,
          ...((root.firstChild.data as LazyLoadRouteData)?.breadcrumb || []),
        ];
        root = root.firstChild;
      }
      return breadcrumb.filter(
        (value, index, self) => self.indexOf(value) === index
      );
    })
  );

  constructor(private router: Router, private overlay: Overlay) {
    this._overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .centerVertically()
        .centerHorizontally(),
      hasBackdrop: true,
      width: 'auto',
      height: 'auto',
    });
  }

  public linkActive(url: string, ignoresStartWith: string[] = []): boolean {
    const snapshotUrl = this.router.url;
    if (ignoresStartWith.some((ignore) => snapshotUrl.startsWith(ignore))) {
      return false;
    }
    return snapshotUrl.startsWith(url);
  }

  public onMenuOpen() {
    if (!this.hamburgerPortal.isAttached) {
      this.hamburgerPortal.attach(this._overlayRef);
    }
  }

  onMenuClose() {
    this.hamburgerPortal.detach();
  }
}
