<a class="recruit" routerLink="recruit" color="main" color-fill>
  <div class="title">RECRUIT</div>
  <div class="message">一緒にチャレンジしませんか？</div>
</a>
<a class="contact" routerLink="contact" color="sub" color-fill>
  <div class="title">CONTACT</div>
  <div class="message">お気軽にお問い合わせください。</div>
</a>
<div class="corporate-detail">
  <div class="logo">
    <a routerLink="/"
      ><img
        loading="lazy"
        src="https://cdn.graat.co.jp/assets/graat_default_white.png"
        alt="graat"
    /></a>
  </div>
  <div class="address">
    <div class="company-name title">グラーツ</div>
    <div>グロース・アーキテクチャ＆チームス株式会社</div>
    <div>東京都新宿区西新宿1-26-2 新宿野村ビル48階</div>
  </div>
  <div class="sns">
    <a href="https://twitter.com/graat_inc"
      ><img
        loading="lazy"
        src="https://cdn.graat.co.jp/assets/twitter-brands.svg"
        alt="twitter"
    /></a>
    <a href="https://www.facebook.com/graat.inc"
      ><img
        loading="lazy"
        src="https://cdn.graat.co.jp/assets/facebook-f-brands.svg"
        alt="facebook"
    /></a>
    <a href="https://www.slideshare.net/graat"
      ><img
        loading="lazy"
        src="https://cdn.graat.co.jp/assets/slideshare-brands.svg"
        alt="slideshare"
    /></a>
  </div>
</div>
<nav class="links">
  <div>
    <a routerLink="service" class="title" routerLink="service">サービス</a>
    <a routerLink="/service/value-definition">価値定義</a>
    <a routerLink="/service/digital-service-design">
      デジタルサービスデザイン (UI/UX)
    </a>
    <a routerLink="/service/microservice">マイクロサービス化設計</a>
    <a routerLink="/service/cloud-shift">クラウドシフト推進</a>
    <a routerLink="/service/dev-ops">DevOps導入支援</a>
    <a routerLink="/service/atlassian-support">アトラシアン製品導入支援</a>
    <a routerLink="/service/organizational-process">組織プロセス支援</a>
    <a routerLink="/service/team-process">チームプロセス支援</a>
  </div>
  <div>
    <a class="title" routerLink="advantage">Graatの強み</a>
    <a class="title" routerLink="case-study">事例紹介</a>
    <a class="title" routerLink="company">企業情報</a>
    <a class="title" routerLink="blogs">ブログ・お知らせ</a>
  </div>
  <div>
    <a routerLink="/recruit" class="title">採用情報</a>
    <a routerLink="/contact/entry">エントリーフォーム</a>
    <a class="title" routerLink="contact">お問い合わせ</a>
  </div>
</nav>
<div class="others">
  <div>
    <div>&#169;graat</div>
    <a routerLink="privacy">プライバシーポリシー</a>
  </div>
</div>
