import { Component } from '@angular/core';
import { UserEventHookTransferStateService } from '@graat-hp/shared/util';
import { Apollo, gql } from 'apollo-angular';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

interface News {
  id: string;
  title: string;
  publicationDate: string;
}

@Component({
  selector: 'graat-hp-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent {
  public readonly articleQueryRef$ = this.apollo.watchQuery<{
    articles: News[];
  }>({
    query: gql`
      query ArticleNews($today: DateTime) {
        articles(
          first: 1
          orderBy: publicationDate_DESC
          ${environment.production ? 'stage: PUBLISHED' : ''}
          where: { publicationDate_lt: $today }
        ) {
          id
          title
          publicationDate
        }
      }
    `,
    variables: {
      today: new Date().toISOString(),
    },
  });

  news$ = this.transferState.useScullyTransferState(
    'article-news',
    this.articleQueryRef$.valueChanges.pipe(
      map((result) => result.data.articles)
    )
  );

  constructor(
    private apollo: Apollo,
    private transferState: UserEventHookTransferStateService
  ) {}
}
