import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';

@Pipe({
  name: 'dateFormatDistance',
})
export class DateFormatDistancePipe implements PipeTransform {
  transform(value: string): string {
    return formatDistance(parseISO(value), new Date(), {
      locale: ja,
      addSuffix: true,
    });
  }
}
