<graat-hp-card-story [routerLink]="pageNavigate">
  <img
    loading="lazy"
    class="cover"
    cover-image
    [src]="cover"
    [attr.alt]="alt"
  />
  <div headline [class.ghost]="!title">{{ title }}</div>
  <div body>
    <div class="description" [class.ghost]="!description">
      {{ description }}
    </div>
    <footer>
      <div class="writer" *ngIf="writer">
        <span class="date" *ngIf="publicationDate">{{
          publicationDate | dateFormatDistance
        }}</span>
        <img
          *ngIf="writer?.photo?.url"
          [src]="writer?.photo?.url"
          [attr.alt]="jobTitle + writer?.name"
        />
        <div class="title">
          <div class="job">{{ jobTitle }}</div>
          <div class="name">{{ writer?.name }}</div>
        </div>
      </div>
      <span graat-hp-tag tag-layout-only *ngFor="let tag of tags">{{
        tag.tags
      }}</span>
    </footer>
  </div>
</graat-hp-card-story>
