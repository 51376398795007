export const creeateConnectionTemplate = (
  name: string,
  where: string,
  gql: string
) =>
  `${name}Connection(${where}){
    edges {
    	cursor
      node { ${gql} }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      pageSize
    }
    aggregate{
      count
    }
  }`;
